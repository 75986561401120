import React from "react"
import About from "../components/About"
import Languages from "../components/Languages"
import Layout from "../components/Layout"
import Skills from "../components/Skills"
import Timeline from "../components/Timeline"
import { I18nProvider } from "@lingui/react"
import catalogES from "../locales/es/messages"
import Projects from "../components/Projects"
import Courses from "../components/Courses"

export default function Home() {
  return (
    <I18nProvider
      language="es"
      catalogs={{
        es: catalogES,
      }}
    >
      <Layout>
        <About />
        <Timeline />
        <Skills />
        <Languages />
        <Projects />
        <Courses />
      </Layout>
    </I18nProvider>
  )
}
